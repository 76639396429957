//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import i18n from '@/vueI18n';

export default {
  name: 'app-invoice-receipt-table',
  props:['receipt'],
  data() {
    return {
      columns: [
        {
          name: 'description',
          field: 'description',
          label: 'entities.invoice.fields.description',
          align: 'center',
        },
        {
          name: 'numberOfReservations',
          field: 'numberOfReservations',
          label: 'entities.invoice.fields.numberOfReservations',
          align: 'center',
        },
        {
          name: 'price',
          field: 'price',
          label: 'entities.invoice.fields.price',
          align: 'center',
        },
        {
          name: 'subtotal',
          field: 'subtotal',
          label: 'entities.invoice.fields.subtotal',
          align: 'center',
        },
      ],
    };
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
  },

  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
    }),
    language() {
      return i18n.locale;
    },
  },
};
