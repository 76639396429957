//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InvoiceReceiptTable from '@/modules/invoice/components/invoice-receipt-table.vue';
import InvoicePaymentTable from '@/modules/invoice/components/invoice-payment-table.vue';
import {mapActions, mapGetters} from 'vuex'
import moment from 'moment';

export default {
  components: {
    [InvoiceReceiptTable.name]: InvoiceReceiptTable,
    [InvoicePaymentTable.name]: InvoicePaymentTable,
  },
  async mounted(){
    await this.doFind(this.$route.params.id)
  },
  data() {
    return {

    };
  },
  computed:{
    ...mapGetters({
      result: 'invoice/view/record',
      loading: 'invoice/view/loading',
      currentLanguageCode: 'layout/currentLanguageCode',
    })
  },
  methods:{
    ...mapActions({
      doFind:'invoice/view/doFind'
    }),
    presenterDate(val) {
      return val ? moment(val).locale('en').format("D MMM, YYYY") : 'ـــــــ'; 
    },
    presenterDateTime(val) {
      return val ? moment(val).locale('en').format("DD-MM-YYYY h:mm A") : 'ـــ'; 
    },
  }
};
