//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import i18n from '@/vueI18n';

export default {
  name: 'app-invoice-payment-table',
  props:['data'],
  data() {
    return {
      columns: [
        {
          name: 'total',
          field: 'total',
          label: 'entities.invoice.fields.total',
          align: 'center',
        },
        {
          name: 'saryahProfit',
          field: 'saryahProfit',
          label: 'entities.invoice.fields.appProfit',
          align: 'center',
        },
        {
          name: 'taxFees',
          field: 'taxFees',
          label: 'entities.invoice.fields.taxFees',
          align: 'center',
        },
        {
          name: 'amountToOwner',
          field: 'amountToOwner',
          label: 'entities.invoice.fields.amountToOwner',
          align: 'center',
        },
        {
          name: 'paymentMethod',
          field: 'paymentMethod',
          label: 'entities.invoice.fields.paymentMethod',
          align: 'center',
        },
      ],
    };
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
  },

  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
    }),
    language() {
      return i18n.locale;
    },
  },
};
